@keyframes blink {
  from {
    color: transparent;
  }
  to {
    position: relative;
    text-shadow: none;
  }
}
.loader__dot {
  animation: 1s blink infinite;
  display: inline-block;
}
.loader__dot:nth-child(2) {
  animation-delay: 250ms;
}
.loader__dot:nth-child(3) {
  animation-delay: 500ms;
}
