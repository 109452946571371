.v-countdown {
  display: flex;
  color: #ea3323;
  flex-direction: row;
  column-gap: 1vmax;
  flex: 1;
  justify-content: end;

  .v-countdown-info {
    display: flex;
    justify-content: center;
    flex-basis: 15%;
  }
}

.tooltip-container {
  color: white !important;
  background: #111827 !important;
  border: 3px solid #3a79de !important;
  --tooltipBackground: #3a79de !important;
  --tooltipBorder: #3a79de !important;
  text-align: center;
  top: 0.75vmin !important;

  .tooltip-arrow {
    left: -1px;
  }
}
