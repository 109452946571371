.mobile .v-navbar {
  .v-button {
    height: 10vmin !important;
    margin-bottom: 2vmax !important;
    font-size: 5vmin !important;
  }

  li {
    flex-direction: column;
    align-items: start !important;
  }

  li:last-child {
    align-items: end !important;
  }

  .v-infoDropdown {
    margin-top: 22vmin;
    width: 25vmin !important;
    font-size: 4vmin !important;
  }
}

.v-navbar {
  margin: 0 auto;
  display: flex;
  list-style: none;
  justify-content: space-between;
  align-items: center;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  padding: 0;
  position: fixed;
  pointer-events: none;

  .v-button {
    height: 5vmin;
    font-size: 3vmin;
    pointer-events: all;
  }

  .v-navbar-right,
  .v-navbar-left {
    align-self: flex-start;
  }

  &.fixed {
    top: 1.5% !important;
    left: 1% !important;
    right: 1% !important;
  }

  li {
    height: 100%;
  }

  .v-infoDropdown {
    margin-top: 2vmax;
    pointer-events: all;
  }

  .v-navbar-left {
    justify-self: flex-start;
    display: flex;
    justify-content: start;
    align-items: center;
    column-gap: 2.5vmin;
    row-gap: 20%;
    flex: 1 0 50%;
  }
  .v-navbar-right {
    justify-self: flex-end;
    display: flex;
    justify-content: end;
    align-items: center;
    column-gap: 2.5vmin;
    row-gap: 20%;
    flex: 1 0 50%;

    .v-navbar-right-icons {
      display: flex;
      justify-content: end;
      column-gap: 2vmin;
    }
  }

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    .v-button {
      margin-bottom: 2vmax !important;
      font-size: 5vmin !important;
      height: 10vmin !important;
    }

    li {
      flex-direction: column;
      align-items: start !important;
    }

    li:last-child {
      align-items: end !important;
    }

    .v-infoDropdown {
      margin-top: 22vmin;
      width: 25vmin !important;
      font-size: 4vmin !important;
    }
  }
}
