.v-claim-reward {
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
  animation: 0.5s showNftAnimation;

  .v-claim-reward-button {
    position: absolute;
    width: 45%;
    left: 27.5%;
    top: 15%;
    height: 75%;
    border-radius: 50%;
  }

  video {
    height: 100%;
    width: 100%;
  }

  @keyframes showNftAnimation {
    from {
      opacity: 0;
    }
  }
}
