.v-stakeListElement {
  width: 100%;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  user-select: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-items: center;
  cursor: default;

  > div {
    margin: 0 1%;
  }

  .v-stakeListElement-icon {
    display: flex;
    align-items: center;
    width: 15%;
    padding: 1.5%;

    .token-icon {
      height: 100%;
      width: 100%;
    }
  }
  .v-stakeListElement-name {
    text-align: left;
    font-size: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .v-stakeListElement-amounts {
    display: flex;
    flex-direction: column;
    font-size: 70%;
    flex: 1;
    margin-right: 8px;

    > div {
      height: 50%;
      line-height: 12px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .v-stakeListElement-amounts-top {
      display: flex;
      margin-bottom: 4px;
      justify-content: flex-end;
      .v-stakeListElement-amounts-top-input {
        margin-right: 4px;

        input {
          font-size: 100%;
          width: 100%;
        }
      }
      .v-stakeListElement-amounts-top-button {
        button {
          font-size: 110%;
          cursor: pointer;
          &:hover,
          &:focus {
            opacity: 0.8;
          }
        }
      }
    }
    .v-stakeListElement-amounts-bottom {
      display: flex;
      justify-content: flex-end;
    }
  }
}
