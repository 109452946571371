.v-button {
  padding: 0;
  position: relative;
  margin: 0;
  border: none;
  background-color: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  font-size: 90%;
  cursor: pointer;
  user-select: none;
  display: flex;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s ease;

  > span {
    width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  > svg {
    width: 100%;
    height: 55%;
    font-size: 8px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &.listElement {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    justify-content: center;
    transition: all 0.3s ease;
  }

  &.disabled {
    cursor: not-allowed;
  }
}
