$dropdownWidth: 13vmax;
.v-buyIndex {
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: inherit;

  .v-buyIndex-top {
    position: relative;
    display: flex;
    align-items: center;
    padding: 7.5% 1%;
    height: 3vmax;

    .v-buyIndex-top-dropdown {
      flex: 1;

      .v-buyIndex-target {
        width: $dropdownWidth;
        column-gap: 11%;
        font-family: inherit;
        display: flex;
        align-items: center;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 100%;
        margin-left: 1%;

        span {
          text-align: left;
        }

        &:hover,
        &:focus {
          opacity: 0.8;
        }
        &.open {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-bottom-color: transparent !important;
        }

        .token-icon {
          height: 3vmax;
          width: 3vmax;
        }

        span:not(.v-buyIndex-carret) {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .no-button {
        cursor: default;
        &:hover {
          opacity: 1;
        }
      }
    }
    .v-buyIndex-top-input {
      flex: 1;
      display: flex;
      align-items: end;
      justify-content: flex-end;
      flex-direction: column;
      margin-right: 3px;

      .v-buyIndex-top-input-left {
        display: flex;
        align-items: center;
        input {
          text-align: right;
          font-family: inherit;
          width: 100%;
        }
      }
      .v-buyIndex-top-input-right {
        margin-left: 4px;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;

        button.v-buyIndex-top-input-right-max {
          font-family: inherit;
          cursor: pointer;
          color: #98de01;
          font-size: 60%;
          padding: 0;
          margin: 0;
          height: 100%;
          &:hover,
          &:focus {
            opacity: 0.8;
          }
        }
      }
    }
  }
  .v-buyIndex-middle {
    width: 100%;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .v-buyIndex-bottom {
    position: relative;
    display: flex;
    padding: 7.5% 1%;
    height: 3vmax;

    .v-buyIndex-bottom-asset {
      font-size: 100%;
      column-gap: 10%;
      margin-left: 1%;
      display: flex;
      align-items: center;
      flex: 1;

      svg {
        width: auto;
        height: 3vmax;
      }
    }
    .v-buyIndex-bottom-values {
      max-width: 200px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      margin-right: 3px;

      .v-buyIndex-bottom-values-eth-qty {
        justify-content: flex-end;
        input {
          font-family: inherit;
          width: 100%;
        }
        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .v-buyIndex-bottom-values-dollar-value {
        opacity: 0.7;
        margin-right: 5px;
        font-size: 9px;
      }
    }
  }
  .v-buyIndex-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 80%;
  }
}

.v-buyIndex-popper {
  width: $dropdownWidth;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: none;
  overflow: hidden;
  margin-left: 0;
  position: absolute;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      height: 40px;
      line-height: 40px;
      margin: 0;
      padding: 8px;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-align: left;

      > svg {
        margin: 0 8px 0 0;
      }
      > span {
        margin-left: 8px;
        font-size: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
      }
    }
  }
}
