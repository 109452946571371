.v-selectListElement {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  background-color: transparent;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  user-select: none;
  display: flex;
  text-align: center;
  align-items: center;
  justify-items: center;
  transition: all 0.3s ease;
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  &:hover,
  &.disabled,
  &:focus {
    opacity: 0.8;
  }

  &.disabled {
    cursor: not-allowed;
    filter: grayscale(80%);
    opacity: 0.6;
  }

  > div {
    margin: 0 1%;
  }

  .v-selectListElement-icon {
    display: flex;
    align-items: center;
    width: 15%;
    padding: 1.5%;

    .token-icon {
      height: 100%;
      width: 100%;
    }
  }
  .v-selectListElement-name {
    font-size: 85%;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .v-selectListElement-details {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-size: 70%;
    height: 100%;
    > div {
      text-align: center;
      height: 50%;
      display: flex;
      justify-content: end;
      align-items: center;
    }
    .v-selectListElement-balance {
      align-items: flex-end;
    }
    .v-selectListElement-staked {
      align-items: flex-start;
    }
  }
}
