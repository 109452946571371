.cookingCrypto-rainbow-text {
  position: relative;
  display: flex;
  justify-content: center;

  
  .cookingCrypto-rainbow-text-wrapper {
    position: absolute;
    z-index: 2;
    background: #FFCC00;
    background: -webkit-linear-gradient(to bottom, #FFCC00 0%, #FFCC00 27%, #C88505 55%, #FFFFFF 100%);
    background: -moz-linear-gradient(to bottom, #FFCC00 0%, #FFCC00 27%, #C88505 55%, #FFFFFF 100%);
    background: linear-gradient(to bottom, #FFCC00 0%, #FFCC00 27%, #C88505 55%, #FFFFFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

  }
}
