.backgroundCover {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  object-fit: cover;
  filter: blur(20px);
}

.error > span {
  color: red;
}

.v-actions {
}
