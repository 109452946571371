html {
  box-sizing: border-box;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
}

@media screen and (max-device-width: 315px),
  screen and (max-device-height: 315px) {
  #root,
  .v-actions {
    filter: blur(5px) brightness(0.25);
    pointer-events: none;
  }

  body::before {
    position: absolute;
    z-index: 101;
    top: 15%;
    content: "";
    mask: url(components/common/assets/loading-logo.svg) no-repeat 50% 50%;
    background-color: red;
    width: 116px;
    height: 116px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  body::after {
    position: absolute;
    z-index: 101;
    content: "Screen size not supported";
    font-family: "Chakra Petch", sans-serif;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    left: 0;
    right: 0;
    color: red;
    margin: 0 auto;
    top: 60%;
  }
}

body.mobile {
  transform: rotate(90deg);
  transform-origin: left top;
  overflow: hidden;
  position: absolute;
  left: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

button {
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

#root,
.page {
  width: 100%;
  height: 100%;
}

.page {
  position: relative;
  top: 0;
}

.frame-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: filter 0.5s ease;
}

.video,
.videoHidden {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.videoHidden {
  opacity: 0;
}

.video {
  opacity: 1;
}

@media screen and (min-device-width: 2000px) and (min-device-height: 1125px) {
  .video,
  .videoHidden {
    object-fit: contain;
  }
}
