.hypedApes-rainbow-text {
  position: relative;
  display: flex;
  justify-content: center;
  font-weight: bold;

  .hypedApes-rainbow-text-wrapper {
    color: #fef167;
    filter: drop-shadow(0 0 0.4xpx white) drop-shadow(0 0 0.4px white);
  }
}
