input.v-input {
  color: inherit;
  width: 110px;
  padding: 3px 6px;
  border: none;
  border-radius: 16px;
  text-align: right;
  outline: none;
  background: transparent;
  &::placeholder {
    color: white;
  }
  &.disabled {
    opacity: .7;
  }
}
