@import "./src/components/common/Actions/actions";

.preloadModalBackgrounds {
  content: url("../GamePages/Fomo/assets/connectExpanded.png")
    url("../GamePages/BullRun/assets/connectExpanded.png")
    url("../GamePages/WhaleMariner/assets/connectExpanded.png")
    url("../GamePages/HypedApes/assets/connectExpanded.png")
    url("../GamePages/DefiInvaders/assets/connectExpanded.png")
    url("../GamePages/CookingCrypto/assets/connectExpanded.png");
  position: absolute;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
}

.spinner {
  background-image: url(../common/assets/spinner.png);
  height: 32px;
  width: 32px;
  animation: spin 1s infinite ease-in-out;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-height: 450px), screen and (max-width: 450px) {
  .v-main-nav .v-button {
    margin-bottom: 2vmax !important;
    font-size: 5vmin !important;
    height: 10vmin !important;
  }

  .v-infoDropdown.main-infoDropdown {
    top: 90% !important;
    width: 15vmax !important;
    font-size: 100% !important;
  }

  .main-tvlDropdown {
    width: 45vmin !important;
    font-size: 75% !important;
    top: 11vmin !important;
  }
}

.mobile {
  .v-main-nav {
    height: 10vmin !important;
    font-size: 5vmin !important;
    .v-button {
      height: 10vmin !important;
      font-size: 5vmin !important;
    }

    .main-tvlDropdown {
      width: 45vmin !important;
      font-size: 75% !important;
    }
  }
  .v-infoDropdown.main-infoDropdown {
    top: 120% !important;
    width: 15vmax !important;
  }
}

.switch-network {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 3;

  .switch-network-container {
    height: 50%;
    width: 50%;
    border: 1px solid white;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 25%;

    h1 {
      color: white;
      margin-top: 0;
    }

    .rectangleLarge {
      width: 50%;
      height: 15%;
    }
  }
}

.loading-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 21;
  background-image: url("./assets/splash.jpeg");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > .loading-screen-logo {
    background: url(../common/assets/loading-logo.svg);
    width: 116px;
    height: 116px;
  }

  > .loading-screen-bar-container {
    height: 12px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 90vw;
    max-width: 350px;
    margin: 30px auto 0 auto;
    border-radius: 65px;

    > .loading-screen-bar {
      height: 100%;
      max-width: 100%;
      border-radius: 65px;
      background: #ffffff;
      transition: width 1s ease-in-out;
    }
  }
}

.v-main-page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;

  .master-reward {
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;

    .master-reward__button {
      margin-top: 25%;
      margin: 0 auto;
    }
  }

  .cabinet-box {
    position: absolute;
    cursor: pointer;
    z-index: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 9;
  }

  .enter {
    font-size: 12px;
  }

  .v-button {
    color: white;
    font-size: 16px;
    font-family: "Chakra Petch", sans-serif;
    &.rectangleLarge {
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 15%/80%;
      padding: 0 2vmax;
      background-color: rgba(0, 0, 0, 0.8);
    }
    &.square {
      aspect-ratio: 1;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 85%/100%;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }

  .v-main-nav {
    position: absolute;
    z-index: 3;
    display: flex;
    justify-content: end;
    align-items: start;
    list-style: none;
    margin: 0 auto;
    width: 100%;

    .claim-button {
      font-weight: 600;
      background-image: url(./assets/claimButton.png);
      text-decoration: none;
      box-shadow: 0 0 1px #fff, 0 0 10px #7bedf9, 0 0 51px #7bedf9,
        0 0 52px #7bedf9, 0 0 102px #7bedf9, 0 0 132px #7bedf9;
    }

    .leaderboard-button {
      background-image: url(./assets/leaderboardButton.png);
      text-decoration: none;
      box-shadow: 0 0 1px #fff, 0 0 10px #F3A831, 0 0 51px #F3A831,
        0 0 52px #F3A831, 0 0 102px #F3A831, 0 0 132px #F3A831;
    }

    .v-button {
      height: 5.5vmin;
      font-size: 2.5vmin;
      pointer-events: all;
      width: 100%;
    }

    &.fixed {
      top: 1.5% !important;
      right: 1% !important;
    }

    svg {
      height: 55% !important;
      width: 55% !important;

      &:last-child {
        left: 5%;
      }
    }
  }
  .cabinet-visibility {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }
  .invisible {
    opacity: 0;
  }
}

.v-actions.main-actions {
  max-height: 40%;
  top: 25%;
  font-family: "Chakra Petch", sans-serif;
  color: white;
  border: 1px solid white;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.8);

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    top: 15%;
    max-height: 75%;
  }

  h2 {
    color: white;
    padding: 8px;
    display: none;
  }

  .connection-list-wrapper {
    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      background-color: rgba(255, 255, 255, 0.2);
      transform: translate(-50%, -50%);
    }

    &:before {
      width: 65%;
      height: 2px;
    }
    &:after {
      height: 65%;
      width: 2px;
    }

    .main-button.listElement {
      font-family: "Chakra Petch", sans-serif;
      color: white;
      border-radius: 12px;
      text-transform: unset;
      background-color: transparent;
      transition: all 0.3s ease;

      span {
        font-size: 125%;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  .invisible {
    opacity: 0;
  }
}

.mobile .v-actions.main-actions {
  max-height: 75%;
}

.v-infoDropdown.main-infoDropdown {
  background-color: rgba(0, 0, 0, 0.75);
  font-family: "Chakra Petch", sans-serif;
  color: white;
  border: 1px solid white;
  border-radius: 16px;
}

.main-tvlDropdown-wrapper {
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;

  .main-tvlDropdown {
    background-color: rgba(0, 0, 0, 0.75);
    font-family: "Chakra Petch", sans-serif;
    color: white;
    padding: 1.5% 1% 1.5% 1.5%;
    border: 1px solid white;
    position: absolute;
    top: 120%;

    .spinner {
      margin: 50px auto;
    }

    .main-tvlDropdown-header {
      > div {
        width: 100%;
        text-align: left;
        font-size: 90%;
      }

      a {
        color: #7bedf9;
        font-size: 80%;
      }
    }

    .main-tvlDropdown-ul {
      margin-top: 8px;
      .main-tvlDropdown-li {
        outline: none;
        border: none;
        user-select: none;
        text-align: center;
        display: flex;
        align-items: center;
        font-size: 80%;

        .logo {
          position: relative;
          height: 100%;
        }

        .logo img {
          filter: grayscale(1);
          width: 90%;
          cursor: not-allowed;

          &.fomo-logo {
            width: 75%;
          }

          &.whaleMariner-logo {
            width: 60%;
          }
        }

        .logo.claimed img {
          filter: none;
          cursor: pointer;
        }

        .left {
          display: flex;
          flex-direction: column;
          text-align: left;
          flex-grow: 1;
          flex-basis: 70%;
          cursor: pointer;

          .title {
            font-weight: normal;
          }
          .amount {
            font-weight: bold;
            font-size: 90%;
          }

          .migrated-row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            gap: 5%;
          }

          .migrated-arrow {
            font-size: 150%;
            background: linear-gradient(to right, #fff, 75%, #7bedf9);
            background-clip: text;
            -webkit-text-fill-color: transparent;
          }

          .migrated-symbol {
            color: #7bedf9;
            text-shadow: 0 0 1px #fff, 0 0 10px #7bedf9, 0 0 51px #7bedf9,
              0 0 52px #7bedf9, 0 0 102px #7bedf9, 0 0 132px #7bedf9;
          }
        }
        .right {
          display: flex;
          justify-content: center;
          flex-basis: 30%;
        }

        &:hover,
        &:focus {
          opacity: 0.8;
        }
      }
    }

    .main-tvlDropdown-button {
      background: linear-gradient(
        264.76deg,
        #0acffe -16.94%,
        #4365ff 116.06%,
        #495aff 116.08%
      );
      border-radius: 25%/85%;
      color: white;
      width: 100%;
      font-family: Chakra Petch;
      cursor: pointer;
      font-size: 80%;
      margin: 0px 10%;
      padding: 4%;
    }
  }
}

.tvl-info-loader-animation {
  animation: tvl-info-loader-animation 3s infinite;

  @keyframes tvl-info-loader-animation {
    0% {
      clip-path: polygon(0 0, 33% 0, 0% 100%, 0% 100%);
    }
    24% {
      clip-path: polygon(0 0, 33% 0, 0% 100%, 0% 100%);
    }
    25% {
      clip-path: polygon(0 0, 33% 0, 33% 100%, 0% 100%);
    }
    49% {
      clip-path: polygon(0 0, 33% 0, 33% 100%, 0% 100%);
    }
    50% {
      clip-path: polygon(0 0, 66% 0, 66% 100%, 0% 100%);
    }
    74% {
      clip-path: polygon(0 0, 66% 0, 66% 100%, 0% 100%);
    }
    75% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    89% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    90% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    99% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
      clip-path: polygon(0 0, 100% 0, 0% 100%, 0% 100%);
    }
  }
}

.tvl-info {
  position: absolute;
  text-align: center;
  background-image: url(assets/SCREEN_MASK.png);
  background-size: 100% 100%;
  padding: 1.5% 1.5% 1% 2%;
  display: flex;
  flex-direction: column;
  color: #92fbee;
  animation: 0.2s showTvlAnimation ease;

  @keyframes showTvlAnimation {
    from {
      opacity: 0;
    }
  }

  .tvl-info-loader {
    margin-top: auto;
    font-weight: 700;
  }

  img {
    align-self: center;
    max-width: 100%;
  }

  .whaleMariner-tvl-info {
    max-width: 90%;
    margin-left: 6%;
  }

  .figures {
    filter: blur(0.5px);
    opacity: 0.9;
    margin-top: auto;
    display: flex;
    flex-direction: column;

    .headers {
      font-weight: 600;
      display: flex;
      font-size: 80%;

      .tvl-staked-header {
        flex: 1;
        text-align: right;
      }
    }

    .values {
      font-weight: 400;
      display: flex;
      .tvl-staked-count {
        flex: 1;
        text-align: right;
      }
    }
  }
}

div[data-radix-portal] {
  width: 100%;
  height: 100%;
  position: fixed !important;
  z-index: 1 !important;
}

.master-reward {
  .master-reward__videos .videoHidden {
    transition: none;
  }

  .migration-text {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 35%;
    color: white;
    text-align: center;
    backdrop-filter: brightness(50%);
    animation: 2s reveal;

    img {
      height: 3vmin;
    }

    h1 {
      font-size: 3.5vmin;
    }

    p {
      font-size: 2vmin;
      margin-top: 0;
    }

    .migration-button-container {
      display:flex;
      gap: 1.5vmin;
    }

    .migration-button {
      height: 5vmin;
      font-size: 2vmin;
      text-decoration: none;
      color: #474a76;
      background-color: #fdfcff;
      box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.06);
      border-radius: 2vmin;
      margin-top: 3vmin;
      font-weight: 600;
    }

    .launch-enso-button {
      color: #1A183F;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.72), rgba(255, 255, 255, 0.72)),
      radial-gradient(102.07% 286.25% at 20.45% -21.25%, #9985FF 0%, rgba(255, 103, 249, 0.88) 53.15%, rgba(255, 168, 87, 0.88) 91.13%);
      background-blend-mode: overlay, normal;
    }
  }

  .migration-text-hide {
    animation: 4s hide;
    opacity: 0;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }
}

@keyframes hide {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
