/* Cooking Crypto */

@font-face {
  font-family: "Arial Rounded Bold";
  src: url("ArialRouBol.ttf") format("truetype");
}

/* Defi Invaders */

@font-face {
  font-family: "VCROSDMono";
  src: url("VCROSDMono.ttf") format("truetype");
}

/* Hyped Apes */

@font-face {
  font-family: "GillSanBol";
  src: url("GillSanBol.ttf") format("truetype");
}

@font-face {
  font-family: "GillSanUltBol";
  src: url("GillSanUltBol.ttf") format("truetype");
}

/* BullRun Racing */

@font-face {
  font-family: "EurostileLTStd";
  src: url("EurostileLTStd-BoldEx2.ttf") format("truetype");
}

/* Whale Mariner */

@font-face {
  font-family: "Bubblegum";
  src: url("Bubblegum.ttf") format("truetype");
}

@font-face {
  font-family: "BalsamiqSansRegular";
  src: url("BalsamiqSans-Regular.ttf") format("truetype");
}

/* FOMO */

@font-face {
  font-family: "StanleyBold";
  src: url("Stanley-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "TWOZARBIIRegular";
  src: url("TWOZARBII-Regular.ttf") format("truetype");
}
