.close-reward {
  height: 5vmin;
  position: absolute;
  z-index: 1;
  right: 30%;
  top: 25%;
  animation: 2s reveal;

  span {
    font-size: 3vmin;
    font-weight: bold;
    text-shadow: 0 0 1vmin white;
  }

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    height: 8vmin;
    span {
      font-size: 4.5vmin;
    }
  }
}

.mobile .close-reward {
  height: 8vmin;
  span {
    font-size: 4.5vmin;
  }
}

.v-actions {
  animation: 0.25s showModalAnimation ease;
  top: 33%;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: 55%;
  margin: 0 auto;
  position: absolute;
  overflow: visible;
  aspect-ratio: 1/1;
  font-size: 80%;

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    max-height: 75%;
    top: 25%;
    left: 28%;
    right: 29%;
    font-size: 120%;
  }

  .v-buy-indexes,
  .v-select-indexes,
  .v-stake-indexes {
    height: 100%;
    justify-content: start !important;
    padding: 0 2% 0 4%;
    width: 100%;
  }

  .v-buyIndex {
    width: 100%;
  }

  @keyframes showModalAnimation {
    from {
      transform: translate(0%, 50%) scale(0.85);
    }
  }

  > h2 {
    text-transform: uppercase;
    top: 4%;
    position: absolute;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    > .v-button {
      position: absolute;
      left: 110px;
    }
  }

  .index-list-scroll {
    width: 100%;
    height: 74%;
    top: 0;
    bottom: -21%;
    overflow: hidden;
    overflow-y: scroll !important;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
    & > div {
      overflow-y: scroll !important;
    }
  }

  .connection-list-wrapper {
    height: 100%;
  }

  .connection-wallet-logo {
    left: 22.5%;
    bottom: -15%;
    width: 55%;
    height: auto;
    pointer-events: none;
    position: absolute;
  }

  .connection-list-wrapper,
  .index-list-wrapper {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .connection-list-wrapper {
    display: grid;
    position: relative;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    place-items: center;
    padding: 0;
    margin: auto;

    .listElement {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      height: 100%;
      text-transform: unset;
      background-image: unset;
      background-color: transparent;
      transition: all 0.3s ease;
      justify-content: space-around;
      padding: 5%;

      &:hover,
      &:focus {
        background: rgba(255, 255, 255, 0.2);
      }

      span {
        height: auto;
        width: auto;
        font-size: 150%;
        white-space: normal;
        padding: 0 5%;

        @media screen and (max-height: 450px), screen and (max-width: 450px) {
          font-size: 100% !important;
        }
      }

      img {
        position: static;
        width: auto;
        height: 60%;
      }
    }
  }

  .v-select-indexes,
  .v-buy-indexes,
  .v-claim,
  .v-stake-indexes {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  h2 {
    font-size: 150%;
  }

  .modalButton {
    white-space: normal;
  }

  .lock-period {
    text-align: center;
    color: white;
    position: fixed;
    bottom: 5%;
    pointer-events: none;
    animation: 2s reveal;
    margin: 0;
    font-size: 80%;
    padding: 2%;
    border-radius: 15px;
    background-color: rgba(0, 0, 0, 0.5);

    @media screen and (max-height: 450px), screen and (max-width: 450px) {
      bottom: 2%;
      left: 1%;
      right: 75%;
    }
  }
}

.mobile .lock-period {
  bottom: 2%;
  left: 1%;
  right: 75%;
}

.mobile .v-actions {
  max-height: 75%;
  top: 25%;
  left: 28%;
  right: 29%;
  font-size: 120%;

  .connection-list-wrapper .listElement span {
    font-size: 100% !important;
  }
}

@keyframes reveal {
  from {
    opacity: 0;
  }
}
