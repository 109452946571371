.v-infoDropdown {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .v-infoDropdown-ul {
    list-style: none;
    padding: 0;

    a {
      text-decoration: none;
      color: unset;
    }

    .v-infoDropdown-li {
      cursor: pointer;
      border: none;
      user-select: none;
      text-align: center;
      padding: 5% 0;

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }
}
