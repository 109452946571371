.hypedApes {
  font-family: "GillSanUltBol";
  text-transform: uppercase;

  .v-input {
    background: #f7b152;
    color: #8f2b11;
  }
  .splash {
    background-image: url("./assets/hypedApes.png");
  }
}

.hypedApes-select-indexes,
.hypedApes-stake-indexes {
  margin-left: -5%;
  height: 110% !important;
  width: 105% !important;
  > .index-list-scroll {
    height: 245px;
  }
  > .v-button {
    margin-top: 12px;
  }
}

.v-button.hypedApes-button {
  font-family: "GillSanUltBol";
  text-transform: uppercase;

  .text {
    transition: -webkit-text-fill-color 0.14s ease-in;
  }

  &:hover {
    .text {
      -webkit-text-fill-color: #2fd13e;
    }
    svg image {
      filter: brightness(0) saturate(100%) invert(59%) sepia(88%) saturate(409%)
        hue-rotate(75deg) brightness(93%) contrast(98%);
    }
  }

  &.big,
  &.modalButton {
    width: 82%;
    height: 30%;
    position: absolute;
    bottom: -20%;
    right: -1%;
    transform: rotate(-5deg);
    text-transform: uppercase;
    overflow: visible;

    &:before {
      z-index: -1;
      right: -24%;
      margin-top: 8%;
      pointer-events: none;
      height: 225%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 147%;
      text-transform: uppercase;
      transform: scaleX(-1);
      background-image: url("./assets/rectangleLarge.png");
    }
  }
  &.rectangleSmall {
    overflow: visible;
    font-weight: 600;
    padding: 0 3%;

    &:before {
      z-index: -1;
      right: -39%;
      margin-top: 9%;
      pointer-events: none;
      height: 275%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 185%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleSmall.png");
    }
  }
  &.rectangleLarge {
    overflow: visible;
    padding: 0 4%;
    margin: 0 1%;

    &:before {
      z-index: -1;
      right: -19%;
      margin-top: 5%;
      pointer-events: none;
      height: 210%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 140%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleLarge.png");
    }
  }
  &.rectangleLargeWarning {
    width: 165px;
    height: 59px;
    background-image: url("./assets/rectangleLargeWarning.png");
  }
  &.square {
    overflow: visible;
    aspect-ratio: 1;

    &:before {
      z-index: -1;
      right: -91%;
      margin-top: 25%;
      pointer-events: none;
      height: 250%;
      width: 275%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      text-transform: uppercase;
      background-image: url("./assets/square.png");
    }

    svg:last-child {
      width: 90%;
      padding-left: 20%;
    }
  }
  &.claim {
    width: 151px;
    height: 59px;
    background-image: url("./assets/big.png");
    color: #8f2b11;
    > span {
      margin-top: -8px;
    }
    .hypedApes-rainbow-text-wrapper {
      color: #8f2b11;
      background: unset;
      -webkit-text-fill-color: unset;
      -webkit-background-clip: unset;
      background-clip: unset;
    }
  }
  &.squareSmall {
    width: 56px;
    height: 40px;
    background-image: url("./assets/square.png");
    > svg {
      right: initial;
      left: 20px;
    }
  }
  &.buyMore {
    width: 134px;
    height: 39px;
    background-image: url("./assets/buyMore.png");
    background-size: contain;
    background-position: center center;
    color: #8f2b11;

    & > span {
      margin: 0;
    }
  }
}

.v-infoDropdown.hypedApes-infoDropdown {
  font-family: "GillSanUltBol";
  color: #fef167;
  text-shadow: 0.5vmin 0.75vmin 0.5vmin rgba(0, 0, 0, 0.5);

  &:before {
    z-index: -1;
    right: -70%;
    top: -37%;
    pointer-events: none;
    height: 180%;
    width: 235%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    text-transform: uppercase;
    background-image: url("./assets/infoDropdown.png");
  }
}

.v-connect-dialog.hypedApes-connect-dialog {
  font-family: "GillSanUltBol";
  text-transform: uppercase;
  background-image: url("./assets/connectShrunk.png");
  width: 387px;
  height: 212px;
  h2 {
    margin-top: 32px;
  }
  button {
    margin: -8px;
    margin-top: -20px;
  }
}

.mobile .v-actions.hypedApes-actions {
  max-height: 60% !important;
  top: 25% !important;
}

.v-actions.hypedApes-actions {
  font-family: "GillSanUltBol";
  text-transform: uppercase;
  overflow: visible;

  max-height: 35%;
  aspect-ratio: 1.1/1;

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    max-height: 60%;
    top: 25%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/connectExpanded.png");
    background-size: 100% 100%;
    height: 220%;
    width: 245%;
    content: "";
    position: absolute;
    top: -70%;
    left: -70%;
    pointer-events: none;
  }

  .connection-list-wrapper {
    height: 80%;
    width: 110%;

    .listElement span {
      font-size: 115%;
    }

    &:before {
      z-index: -1;
      background-image: url("./assets/cross.png");
      background-size: 100% 100%;
      height: 104%;
      width: 102%;
      content: "";
      position: absolute;
      top: -1%;
      left: -1%;
      pointer-events: none;
    }
  }

  .connection-wallet-logo {
    width: 60%;
    left: 22.5%;
    bottom: -28%;
  }

  .hypedApes-buy-indexes,
  .hypedApes-claim {
    margin-top: 20px;
    padding-right: 20px;
    > img {
      width: 100%;
      max-width: 200px;
      max-height: 200px;
      border: none;
      border-radius: 34px;
      margin: auto;
      background-color: #59a20066;
      object-fit: contain;
    }
  }
  h2 {
    top: -25%;
    font-size: 150%;
    transform: rotate(5deg);

    > .v-button {
      left: -16%;
      width: 65px;
      height: 65px;
      transform: rotate(5deg);

      svg {
        padding: 0 5px;
        transform: rotate(-5deg);
      }
    }
  }
}

.v-selectListElement.hypedApes-selectListElement {
  font-family: "GillSanUltBol";
  background-image: url("./assets/indexLi.png");
  color: #fef167;
  padding: 2% 21% 2% 0;
  font-size: 90%;

  .v-selectListElement-name {
    font-size: 75%;
  }

  &:first-child {
    margin-top: 0;
  }
  &.selected {
    background-image: url("./assets/indexLiSelected.png");
  }

  .hypedApes-selectListElement-details {
    font-size: 60%;
  }
}

.v-stakeListElement.hypedApes-stakeListElement {
  color: white;
  font-size: 90%;

  .v-stakeListElement-name {
    font-size: 75%;
  }

  &:first-child {
    margin-top: 10%;
  }

  .v-stakeListElement-amounts-top-input {
    input {
      font-family: "GillSanUltBol";
      text-transform: uppercase;

      &::placeholder {
        color: #8f2b11;
      }
    }
  }
  .v-stakeListElement-amounts-top-button {
    button {
      font-family: "GillSanUltBol";
      text-transform: uppercase;
      font-weight: bold;
      color: #59a200;
    }
  }
}

.v-buyIndex.hypedApes-buyIndex {
  .v-buyIndex-top.hypedApes-buyIndex-top {
    color: white;

    .v-buyIndex-target.hypedApes-buyIndex-target.open {
      background-image: url("assets/connectExpanded.png");
      background-repeat: no-repeat;
      background-position: center center;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }

    .v-buyIndex-top-dropdown.hypedApes-buyIndex-top-dropdown {
      .v-buyIndex-target.hypedApes-buyIndex-target {
        color: white;
      }
    }
    .v-buyIndex-top-input.hypedApes-buyIndex-top-input {
      button {
        color: #59a200;
      }
    }
  }

  .v-buyIndex-bottom.hypedApes-buyIndex-bottom {
    color: white;
  }
  .v-buyIndex-footer.hypedApes-buyIndex-footer {
    color: white;
  }
}

.v-buyIndex-popper.hypedApes-buyIndex-popper {
  font-family: "GillSanUltBol";
  text-transform: uppercase;
  color: #8f2b11;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 2px;
  background-image: url("assets/connectExpanded.png");
  background-repeat: no-repeat;
  background-position: center center;

  ul {
    color: #fef167;
  }
}
