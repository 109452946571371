.notifications {
  z-index: 101;

  .Toastify__toast {
    position: relative;
    padding: 2px;
    border-radius: 6px;
    box-shadow: none;
    background-color: transparent;
    overflow: visible;
    transition: all 0.3s ease;

    &:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      border-radius: 6px;
      background-color: #000000cc;
      border: 2px solid #ffffff33;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease;
      z-index: -1;
    }

    .Toastify__toast-body {
      padding: 18px 48px 18px 14px;
      transition: all 0.3s ease;
    }
    .Toastify__close-button {
      position: absolute;
      top: 50%;
      right: 16px;
      opacity: 1;
      color: #ffffff;
      transform: translateY(-50%);
      transition: all 0.3s ease;
    }
    .Toastify__toast-icon {
      width: 40px;
      height: 40px;
      font-size: 32px;
      line-height: 40px;
      vertical-align: middle;
      margin-inline-end: 0;
      margin-right: 16px;
    }
    & h4 {
      margin: 0 0 4px;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      transition: all 0.3s ease;
    }
    & span {
      font-size: 10px;
      line-height: 14px;
      color: #ffffff;
      transition: all 0.3s ease;
    }
  }

  .Toastify__toast.fomo-notification {
    background: linear-gradient(90deg, #1f0300 5.5%, #450b0a 50%, #1f0300 100%);
    &:before {
      background-color: #8f7c4573;
    }
    .Toastify__close-button,
    & h4,
    & span {
      color: #ffffff;
    }
  }
  .Toastify__toast.bullRun-notification {
    background: #050f13;
    &:before {
      background: linear-gradient(180deg, #3872a0, #cce6f8);
    }
    .Toastify__close-button,
    & h4,
    & span {
      color: #ffffff;
    }
  }
  .Toastify__toast.whaleMariner-notification {
    background: linear-gradient(195deg, #23dfe8, #0b41cd 200%);
    &:before {
      background: #21f5ff;
    }
    &.Toastify__toast--error {
      background: linear-gradient(175deg, #f3949d 35%, #ebc7ca 93%);
      &:before {
        background: #f3949d;
      }
    }
    .Toastify__close-button,
    & h4,
    & span {
      color: #ffffff;
    }
  }
  .Toastify__toast.hypedApes-notification {
    background: #70210e;
    &:before {
      background: #98de01;
    }
    &.Toastify__toast--error {
      background: #eb5757;
      &:before {
        background: #98de01;
      }
    }
    .Toastify__close-button,
    & h4,
    & span {
      color: #ffffff;
    }
  }
  .Toastify__toast.defiInvaders-notification {
    background: #2d2d51;
    &:before {
      background: linear-gradient(180deg, #77d6d3, #e2f4f3, #303356);
    }
    &.Toastify__toast--error {
      background: #2d2d51;
      &:before {
        background: linear-gradient(180deg, #f2455a, transparent, #f2455a);
      }
    }
    .Toastify__close-button,
    & h4,
    & span {
      color: #ffffff;
    }
  }
  .Toastify__toast.cookingCrypto-notification {
    background: linear-gradient(90deg, #f3ff39, #5cfe6a);
    &:before {
      background: #fe7c0a;
    }
    &.Toastify__toast--error {
      background: linear-gradient(90deg, #d03b4d, #f34459);
      &:before {
        background: #ffffff;
      }
    }
    .Toastify__close-button,
    & h4,
    & span {
      color: #393550;
    }
  }
}
