.bullRun {
  font-family: "EurostileLTStd";
  font-size: 12px;
  color: #ffffff;
  .v-input {
    color: white;
    background: black;
  }
  .splash {
    background-image: url("./assets/bullRun.png");
  }
  .v-navbar .v-button {
    font-size: 2vmin;
  }
}

.bullRun-button {
  text-transform: uppercase;
  color: #ffffff;
  font-family: "EurostileLTStd";
  font-size: 10px;
  padding: 8px 12px 12px;
  background-position: center center;

  .text {
    transition: text-shadow 0.18s ease-in;
  }

  &:hover {
    .text {
      text-shadow: 0 0 1px #2fd13e, 0 0 2px #2fd13e, 0 0 3px #2fd13e,
        0 0 4px #2fd13e, 0 0 5px #2fd13e, 0 0 6px #2fd13e, 0 0 8px #2fd13e,
        0 0 10px #2fd13e, 0 0 15px #2fd13e;
    }
    svg image {
      filter: drop-shadow(0 0 1px #2fd13e) drop-shadow(0 0 3px #2fd13e)
        drop-shadow(0 0 5px #2fd13e);
    }
  }

  &.rectangleSmall {
    overflow: visible;
    padding: 0 3%;

    &:before {
      z-index: -1;
      right: -25%;
      top: -45%;
      pointer-events: none;
      height: 210%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 150%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleSmall.png");
    }
  }
  &.modalButton {
    font-size: 2vmin;
    width: 156px;
    height: 55px;
    bottom: 0;
    width: 100%;
    height: 15%;
    position: absolute;
  }

  &.rectangleLarge,
  &.big {
    overflow: visible;
    padding-left: 15px;
    padding: 0 4%;
    margin: 0 1%;

    &:before {
      z-index: -1;
      left: 0;
      pointer-events: none;
      height: 100%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 100%;
      background-image: url("./assets/rectangleLarge.png");
    }
  }
  &.big {
    font-size: 18px;
  }
  &.rectangleLargeWarning {
    width: 156px;
    height: 55px;
    font-size: 10px;
    background-image: url("./assets/rectangleLargeWarning.png");
  }
  &.square {
    aspect-ratio: 1;
    overflow: visible;

    &:before {
      z-index: -1;
      left: 0;
      pointer-events: none;
      height: 100%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 100%;
      background-image: url("./assets/square.png");
    }
    svg image {
      transition: filter 0.18s ease-in;
    }
  }

  &.squareSmall {
    width: 30px;
    height: 30px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
  &.squareSmall {
    width: 30px;
    height: 30px;

    svg {
      height: 100%;
      width: 100%;
    }
  }
  &.claim {
    width: 156px;
    height: 55px;
    background-image: url("./assets/claim.png");
  }
  &.buyMore {
    font-size: 16px;
    margin-bottom: 4px;
    padding: 10px 24px 12px;
    height: 40px;
    background-image: url("./assets/rectangleLarge.png");
    background-size: contain;
  }
}

.v-infoDropdown.bullRun-infoDropdown {
  font-family: "EurostileLTStd";
  color: #ffffff;

  &:before {
    z-index: -1;
    top: -1.5%;
    left: -5%;
    pointer-events: none;
    height: 105%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    width: 110%;
    background-image: url("./assets/infoDropdown.png");
  }
}

.v-connect-dialog.bullRun-connect-dialog {
  background-image: url("./assets/connectShrunk.png");
  width: 369px;
  height: 200px;
  font-family: "EurostileLTStd";
  color: #ffffff;
  h2 {
    text-transform: uppercase;
    font-size: 35px;
    line-height: 35px;
  }
}

.mobile .v-actions.bullRun-actions {
  max-height: 100%;
  top: 20%;
}

.v-actions.bullRun-actions {
  font-family: "EurostileLTStd";
  color: #ffffff;
  overflow: visible;

  max-height: 45%;
  aspect-ratio: 1.1/1;

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    max-height: 100%;
    top: 20%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/connectExpanded.png");
    background-size: 100% 100%;
    height: 140%;
    width: 114%;
    content: "";
    position: absolute;
    top: -24%;
    left: -11%;
    pointer-events: none;
  }

  .v-buy-indexes,
  .v-select-indexes,
  .v-stake-indexes,
  .connection-list-wrapper {
    height: 100%;
    justify-content: start !important;
    padding: 0 12% 0 12%;
    width: 100%;
  }

  .connection-list-wrapper {
    height: 85%;
    padding-top: 1%;

    .listElement span {
      font-size: 115%;
    }

    &:before {
      z-index: -1;
      background-image: url("./assets/cross.png");
      background-size: 100% 100%;
      height: 95%;
      width: 75%;
      content: "";
      position: absolute;
      top: 2.5%;
      left: 12.5%;
      pointer-events: none;
    }
  }

  .connection-wallet-logo {
    left: 21%;
  }

  .v-claim img {
    width: 100%;
    max-width: 166px;
    max-height: 166px;
    border-radius: 50%;
  }
  .bullRun-claim {
    height: 276px;

    .v-button {
      font-size: 14px;
    }
  }
  > h2 {
    text-transform: uppercase;
    top: -16%;
    position: absolute;
    margin: 0;
    width: 100%;

    > .v-button {
      left: 32px;
    }
  }
  > p > .v-button.rectangleLarge {
    margin-top: 56px;
  }
}

.v-selectListElement.bullRun-selectListElement {
  width: 100%;
  background-image: url("./assets/indexLi.png");
  background-size: 100%;
  padding: 2% 16% 2% 0;

  .v-selectListElement-name {
    font-size: 75%;
  }

  .v-selectListElement-details {
    font-size: 50%;
  }

  .bullRun-selectListElement-details {
    color: white;
  }

  &.selected {
    background-image: url("./assets/indexLiSelected.png");
  }
}

.v-stakeListElement.bullRun-stakeListElement {
  background-image: url("assets/stakeLi.png");
  background-size: 100%;
  cursor: default;

  .v-stakeListElement-icon {
    > svg {
      display: flex;
      justify-content: center;
    }
  }
  .v-stakeListElement-name {
    color: #ffffff;
    font-size: 85%;
  }

  .v-stakeListElement-amounts {
    color: white;
    font-size: 60%;

    .v-stakeListElement-amounts-top-input {
      input {
        font-family: "EurostileLTStd";
      }
    }
    .v-stakeListElement-amounts-top-button {
      button {
        font-family: "EurostileLTStd";
        font-weight: bold;
        color: #ffffff;
      }
    }
  }

  .v-stakeListElement-amounts-bottom {
    color: #bdbdbd;
  }
}

.v-buyIndex.bullRun-buyIndex {
  font-size: 60%;

  .v-buyIndex-top.bullRun-buyIndex-top {
    background-size: 100%;
    background-image: url("assets/stakeLi.png");

    .v-buyIndex-top-dropdown.bullRun-buyIndex-top-dropdown {
      .v-buyIndex-target.bullRun-buyIndex-target {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;

        color: #ffffff;
        text-transform: uppercase;
        &.open {
          background: #1d2123;
        }
      }
    }
    .v-buyIndex-top-input.bullRun-buyIndex-top-input {
      input {
        color: white;
      }
      button {
        color: #ffffff;
      }
    }
  }
  .v-buyIndex-bottom.bullRun-buyIndex-bottom {
    background-image: url("assets/stakeLi.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .v-buyIndex-bottom-asset.bullRun-buyIndex-bottom-asset {
      text-transform: uppercase;
    }
    .v-buyIndex-bottom-values.bullRun-buyIndex-bottom-values {
      color: white;
    }
  }
  .v-buyIndex-footer.bullRun-buyIndex-footer {
    color: white;
  }
}

.v-buyIndex-popper.bullRun-buyIndex-popper {
  z-index: 1;
  font-family: "EurostileLTStd";
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-left: 4px;
  background-image: url("assets/connectExpanded.png");
  background-repeat: no-repeat;
  background-position: center center;

  ul {
    li {
      > span {
        text-transform: uppercase;
        font-size: 100%;
        color: #ffffff;
      }
    }
  }
}
