.whaleMariner {
  font-family: "Bubblegum";
  text-transform: uppercase;
  .v-input {
    background: #4193f3;
    color: white;
  }
  .splash {
    background-image: url("./assets/whaleMariner.png");
  }
}

.v-button.whaleMariner-button {
  font-family: "Bubblegum";
  background: -webkit-linear-gradient(
    to bottom,
    #4dffd2 0%,
    #4dffd2 27%,
    #f6f7f3 55%,
    #fbf5cc 100%
  );
  background: -moz-linear-gradient(
    to bottom,
    #4dffd2 0%,
    #4dffd2 27%,
    #f6f7f3 55%,
    #fbf5cc 100%
  );
  background: linear-gradient(
    to bottom,
    #4dffd2 0%,
    #4dffd2 27%,
    #f6f7f3 55%,
    #fbf5cc 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  .text {
    color: white;
    transition: text-shadow 0.18s ease-in;
  }

  &:hover {
    .text {
      text-shadow: 0 0 1px #efff47, 0 0 2px #efff47;
    }

    svg image {
      filter: brightness(0) saturate(100%) invert(100%) sepia(63%)
        saturate(2567%) hue-rotate(324deg) brightness(120%) contrast(107%);
    }
  }

  &.modalButton {
    width: 62%;
    height: 17%;
    top: 2%;
    text-transform: uppercase;
    overflow: visible;
    font-size: 3vmin;
  }

  &:before {
    z-index: -1;
    left: 0;
    pointer-events: none;
    height: 100%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    width: 100%;
    background-image: url("./assets/rectangleLarge.png");
  }
  &.rectangleSmall {
    overflow: visible;
    padding: 0 3%;

    &:before {
      z-index: -1;
      left: 0;
      pointer-events: none;
      height: 100%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 100%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleSmall.png");
    }

    > svg:first-child {
      right: initial;
      left: 12px;
    }
  }
  &.rectangleLarge,
  &.big {
    overflow: visible;
    padding: 0 4%;
    margin: 0 1%;

    &:before {
      z-index: -1;
      left: 0;
      pointer-events: none;
      height: 100%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 100%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleLarge.png");
    }
  }
  &.rectangleLargeWarning {
    width: 183px;
    height: 40px;
    font-size: 16px;
    background-image: url("./assets/rectangleLargeWarning.png");
  }
  &.square {
    aspect-ratio: 1;
    overflow: visible;

    &:before {
      z-index: -1;
      left: 0;
      pointer-events: none;
      height: 100%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 100%;
      text-transform: uppercase;
      background-image: url("./assets/square.png");
    }
  }
  &.claim {
    width: 136px;
    height: 39px;
    color: black;
    font-size: 14px;
    background-image: url("./assets/claim.png");
  }
  &.squareSmall {
    &:before {
      background-image: url("./assets/squareSmall.png");
    }
  }
  &.listElement:before {
    background-image: none;
  }
  &.buyMore {
    padding: 10px 12px 12px;
    color: white;
    margin-bottom: 16px;
    background-image: url("./assets/buyMore.png");
    width: 140px;
    height: 39px;
    font-size: 16px;
  }
}

.v-infoDropdown.whaleMariner-infoDropdown {
  font-family: "Bubblegum";

  li {
    background: -webkit-linear-gradient(
      to bottom,
      #4dffd2 0%,
      #4dffd2 27%,
      #f6f7f3 55%,
      #fbf5cc 100%
    );
    background: -moz-linear-gradient(
      to bottom,
      #4dffd2 0%,
      #4dffd2 27%,
      #f6f7f3 55%,
      #fbf5cc 100%
    );
    background: linear-gradient(
      to bottom,
      #4dffd2 0%,
      #4dffd2 27%,
      #f6f7f3 55%,
      #fbf5cc 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:before {
    z-index: -1;
    pointer-events: none;
    height: 105%;
    width: 100%;
    top: -1%;
    left: -1%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    text-transform: uppercase;
    background-image: url("./assets/infoDropdown.png");
  }
}

.v-connect-dialog.whaleMariner-connect-dialog {
  font-family: "Bubblegum";
  font-size: 14px;
  background-image: url("./assets/connectShrunk.png");
  height: 186px;
  width: 388px;
  h2 {
    line-height: 35px;
    font-size: 14px;
    margin-top: 48px;
  }
  p button {
    margin-top: 4px;
  }
}

.mobile .v-actions.whaleMariner-actions {
  max-height: 100% !important;
  top: 15% !important;
}

.v-actions.whaleMariner-actions {
  font-family: "Bubblegum";
  color: #ffffff;
  top: 23%;

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    max-height: 100%;
    top: 15%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/connectExpanded.png");
    background-size: 100% 100%;
    height: 140%;
    width: 107%;
    content: "";
    position: absolute;
    top: -21%;
    left: -4%;
    pointer-events: none;
  }

  &.whaleMariner-actions-connect-wallet:after {
    background-image: none;
  }

  &:after {
    z-index: -1;
    position: absolute;
    content: "";
    top: 20%;
    left: 8.5%;
    right: 11.5%;
    bottom: 26%;
    opacity: 0.6;
    background-image: url(assets/connectExpandedContent.png);
    background-size: 100% 100%;
  }

  .v-buy-indexes,
  .v-select-indexes,
  .v-stake-indexes {
    position: absolute;
    padding: 0;
    top: 22%;
    left: 10%;
    right: 12%;
    width: auto;
    height: 68%;
  }

  .v-buyIndex {
    height: 74%;
  }

  .connection-list-wrapper {
    top: 25%;
    left: 10%;
    right: 13%;
    width: 60%;
    height: 55%;
    position: absolute;

    &:before {
      z-index: -1;
      background-image: url("./assets/cross.png");
      background-size: 100% 100%;
      height: 104%;
      width: 103%;
      content: "";
      position: absolute;
      pointer-events: none;
    }

    .listElement span {
      font-size: 125%;
      background: linear-gradient(
        to bottom,
        #4dffd2 0%,
        #4dffd2 27%,
        #f6f7f3 55%,
        #fbf5cc 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .connection-wallet-logo {
    display: none;
  }

  .v-claim-reward {
    margin: 20px;
    img {
      width: 100%;
      max-width: 203px;
      max-height: 203px;
      border: 4px solid #1a7bc0;
      border-radius: 8px;
      margin: auto;
      background-color: #35b1e6;
      object-fit: contain;
    }
  }
  h2 {
    font-weight: 100;
    left: 25%;
    width: 48%;
    height: 15%;
    top: 6.5%;
    font-size: 120%;
    text-transform: uppercase;
    background-image: url(assets/connectExpandedTitle.png);
    background-size: 100% 100%;
    span {
      background: linear-gradient(
        to bottom,
        #4dffd2 0%,
        #4dffd2 27%,
        #f6f7f3 55%,
        #fbf5cc 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    > .v-button {
      width: 25%;
      height: 70%;
      left: -23%;

      svg {
        height: 75%;
      }
    }
  }
}

.v-selectListElement.whaleMariner-selectListElement {
  background-image: url("./assets/indexLi.png");
  padding: 2% 18% 2% 0;
  color: white;
  font-size: 120%;

  .whaleMariner-selectListElement-name {
    color: yellow;
    font-size: 125%;
  }

  &.selected {
    background-image: url("./assets/indexLiSelected.png");
  }
}

.v-stakeListElement.whaleMariner-stakeListElement {
  font-size: 120%;

  .v-stakeListElement-icon {
    > svg {
      display: flex;
      justify-content: center;
    }
  }
  .v-stakeListElement-name {
    color: yellow;
    font-size: 125%;
  }

  .v-stakeListElement-amounts {
    .v-stakeListElement-amounts-top-input {
      input {
        font-family: "Bubblegum";
      }
    }
    .v-stakeListElement-amounts-top-button {
      button {
        font-family: "Bubblegum";
        font-weight: bold;
        color: yellow;
      }
    }
  }
}

.v-buyIndex.whaleMariner-buyIndex {
  .v-buyIndex-top.whaleMariner-buyIndex-top {
    .v-buyIndex-target.whaleMariner-buyIndex-target.open {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      background-image: url("assets/connectExpanded.png");
      background-repeat: no-repeat;
      background-position: center center;
      color: yellow;
    }

    .v-buyIndex-target.whaleMariner-buyIndex-target {
      color: yellow;

      span {
        padding: 5px 0;
      }

      .v-buyIndex-carret {
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }

    .v-buyIndex-top-input.whaleMariner-buyIndex-top-input {
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      .v-buyIndex-top-input-right {
        button.v-buyIndex-top-input-right-max {
          color: yellow;
        }
      }
    }
  }
  .v-buyIndex-bottom.whaleMariner-buyIndex-bottom {
    .v-buyIndex-bottom-asset.whaleMariner-buyIndex-bottom-asset {
      text-transform: uppercase;
      color: yellow;
    }
    .v-buyIndex-bottom-values.whaleMariner-buyIndex-bottom-values {
      .v-buyIndex-bottom-values-dollar-value.whaleMariner-buyIndex-bottom-values-dollar-value {
        span {
          font-weight: 600;
        }
      }
    }
  }
  .v-buyIndex-footer.whaleMariner-buyIndex-footer {
    color: #fff;
  }
}

.v-buyIndex-popper.whaleMariner-buyIndex-popper {
  font-family: "Bubblegum";
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-left: 2px;
  background-image: url("assets/connectExpanded.png");
  background-repeat: no-repeat;
  background-position: center center;
  ul {
    color: yellow;
  }
}

.v-button.whaleMariner-button.close-reward span {
  background: linear-gradient(
    to bottom,
    #4dffd2 0%,
    #4dffd2 27%,
    #f6f7f3 55%,
    #fbf5cc 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
