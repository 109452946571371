.fomo {
  font-family: "TWOZARBIIRegular";
  text-transform: uppercase;
  color: #edd7ac;
  input.v-input {
    border-radius: 0;
    background: #1f2120;
  }
  .splash {
    background-image: url("./assets/fomo.png");
  }
}

.v-button.fomo-button {
  color: #edd7ac;
  font-family: "TWOZARBIIRegular";
  text-transform: uppercase;
  .text {
    transition: text-shadow 0.18s ease-in, color 0.18s ease-in;
  }
  &:hover {
    .text {
      color: #bf2c2c;
      text-shadow: 0 0 1px #bf2c2c, 0 0 2px #bf2c2c, 0 0 4px #bf2c2c,
        0 0 6px #bf2c2c, 0 0 10px #bf2c2c;
    }
    svg image {
      filter: drop-shadow(0 0 1px #bf2c2c) drop-shadow(0 0 3px #bf2c2c)
        drop-shadow(0 0 5px #bf2c2c);
    }
  }

  &.modalButton {
    width: 250px;
    height: 50px;
    position: absolute;
    bottom: 15px;
    text-transform: uppercase;
    overflow: visible;

    span {
      margin-top: 10px;
    }

    &:before {
      z-index: -1;
      right: -35%;
      margin-top: 15%;
      pointer-events: none;
      height: 400%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 170%;
      padding-bottom: 5px;
      text-transform: uppercase;
      background-image: url("./assets/rectangleLarge.png");
    }
  }

  &.rectangleSmall {
    text-transform: uppercase;
    font-weight: bold;
    overflow: visible;
    padding: 0 3%;

    &:before {
      z-index: -1;
      right: -75%;
      top: -215%;
      pointer-events: none;
      height: 550%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 250%;
      padding-bottom: 5px;
      text-transform: uppercase;
      background-image: url("./assets/rectangleSmall.png");
    }

    > svg:first-child {
      right: initial;
      left: 8px;
    }
  }
  &.rectangleLarge,
  &.big {
    overflow: visible;
    padding: 0 4%;
    margin: 0 1%;

    &:before {
      z-index: -1;
      right: -35%;
      top: -160%;
      pointer-events: none;
      height: 475%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 170%;
      padding-bottom: 5px;
      text-transform: uppercase;
      background-image: url("./assets/rectangleLarge.png");
    }
  }
  &.rectangleLargeWarning {
    color: #f34459;
    width: 132px;
    height: 40px;
    font-size: 16px;
    background-image: url("./assets/rectangleLargeWarning.png");
  }
  &.square {
    width: 52px;
    height: 52px;
    background-image: url("./assets/square.png");
    svg image {
      transition: filter 0.18s ease-in;
    }
  }
  &.claim {
    width: 132px;
    height: 40px;
    background-image: url("./assets/claim.png");
  }
  &.squareSmall {
    aspect-ratio: 1;
    overflow: visible;

    &:before {
      z-index: -1;
      right: -265%;
      margin-top: 15%;
      pointer-events: none;
      height: 450%;
      width: 635%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      text-transform: uppercase;
      background-image: url("./assets/squareSmall.png");
    }

    svg {
      height: 100%;
      width: 100%;
      padding-top: 5px;
    }

    &:last-child svg {
      padding-top: 8px;
    }
  }
  &.buyMore {
    margin-bottom: 4px;
    background-image: url("./assets/buyMore.png");
    width: 89px;
    height: 40px;
    font-size: 16px;
  }
}

.v-infoDropdown.fomo-infoDropdown {
  font-family: "TWOZARBIIRegular";
  text-transform: uppercase;
  color: #edd7ac;

  &:before {
    z-index: -1;
    right: -36%;
    top: -22%;
    pointer-events: none;
    height: 155%;
    width: 180%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    text-transform: uppercase;
    background-image: url("./assets/infoDropdown.png");
  }
}

.v-connect-dialog.fomo-connect-dialog {
  width: 434px;
  height: 200px;
  font-family: "TWOZARBIIRegular", cursive;
  text-transform: uppercase;
  color: #edd7ac;
  background-image: url("./assets/connectShrunk.png");
  h2 {
    margin-top: 44px;
    font-size: 35px;
    line-height: 35px;
  }
}

.mobile .v-actions.fomo-actions {
  max-height: 70%;
  top: 20%;
}

.v-actions.fomo-actions {
  font-family: "TWOZARBIIRegular";
  text-transform: uppercase;
  color: #edd7ac;
  overflow: visible;

  max-height: 40%;

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    max-height: 70%;
    top: 20%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/connectExpanded.png");
    background-size: 100% 100%;
    height: 200%;
    width: 170%;
    content: "";
    position: absolute;
    top: -65%;
    left: -32%;
    pointer-events: none;
  }

  .connection-list-wrapper {
    height: 75%;
    padding-top: 2%;

    .listElement span {
      font-size: 100%;
    }

    &:before {
      z-index: -1;
      background-image: url("./assets/CROSS.png");
      background-size: 100% 100%;
      height: 103%;
      width: 103%;
      content: "";
      position: absolute;
      top: 1.5%;
      left: -1.5%;
      pointer-events: none;
    }
  }

  .index-list-wrapper {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0px;
  }
  .fomo-claim {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 332px;
  }
  .fomo-claim-reward {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    & > img {
      width: 100%;
      max-width: 264px;
      max-height: 286px;
      border: 2px solid #2b2b2b;
      border-radius: 0;
      margin: auto;
      background-color: #000000;
      object-fit: contain;
    }
  }
  .fomo-buyIndex {
    margin-top: 5%;

    input.v-input {
      border-radius: 0;
    }
    .v-buyIndex-popper {
      li {
      }
    }
  }
  h2 {
    top: -12%;
    left: 2%;
    font-size: 110%;

    font-size: 2.5vmin;
    background-color: #404040;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: rgba(245, 245, 245, 0.5) 2px 0px 1px;

    span {
      text-transform: uppercase;
    }

    > .v-button {
      left: -5px;
      bottom: 5px;
      position: relative;

      &:before {
        display: none;
      }
    }
  }
}

.v-selectListElement.fomo-selectListElement {
  background-image: url("./assets/indexLi.png");
  color: white;
  padding: 4% 16% 4% 0;

  .v-selectListElement-name {
    font-size: 80%;
  }

  .v-selectListElement-details {
    font-size: 60%;
  }

  &:first-child {
    margin-top: 5%;
  }

  &.selected {
    background-image: url("./assets/indexLiSelected.png");
  }
}

.v-stakeListElement.fomo-stakeListElement {
  &:first-child {
    margin-top: 5%;
  }

  .v-stakeListElement-amounts {
    color: white;
    .v-stakeListElement-amounts-top-input {
      input {
        font-family: "TWOZARBIIRegular";
      }
    }
    .v-stakeListElement-amounts-top-button {
      button {
        font-family: "TWOZARBIIRegular";
        text-transform: uppercase;
        font-weight: bold;
        text-transform: uppercase;
        color: #edd7ac;
      }
    }
  }
}

.v-buyIndex.fomo-buyIndex {
  .v-buyIndex-top.fomo-buyIndex-top {
    .v-buyIndex-top-dropdown.fomo-buyIndex-top-dropdown {
      .v-buyIndex-target.fomo-buyIndex-target {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        color: white;
        text-transform: uppercase;
      }
    }
    .v-buyIndex-top-input.fomo-buyIndex-top-input {
      button {
        color: #edd7ac;
      }
    }
  }

  .v-buyIndex-bottom.fomo-buyIndex-bottom {
    .v-buyIndex-bottom-asset.fomo-buyIndex-bottom-asset {
      text-transform: uppercase;
      color: white;
    }
  }
  .v-buyIndex-footer.fomo-buyIndex-footer {
    color: white;
  }
}

.v-buyIndex-popper.fomo-buyIndex-popper {
  font-family: "TWOZARBIIRegular";
  text-transform: uppercase;
  border-color: #3f371e;
  //background: rgba(16, 16, 14, 1);
  color: white;
  text-transform: uppercase;
  border-radius: 8px;
  background-image: url("assets/connectExpanded.png");
  background-repeat: no-repeat;
  background-position: center center;
}
