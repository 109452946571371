.cookingCrypto {
  font-family: "Arial Rounded Bold";
  .v-input {
    font-size: 16px;
    text-transform: uppercase;
    background: #f0f1f3aa;
  }
  .splash {
    background-image: url("./assets/cookingCrypto.png");
  }
}

.v-button.cookingCrypto-button {
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Arial Rounded Bold";

  .text {
    transition: text-shadow 0.18s ease-in;
    background: #ffcc00;
    background: -webkit-linear-gradient(
      to bottom,
      #ffcc00 0%,
      #ffcc00 27%,
      #ffd689 55%,
      #ffffff 100%
    );
    background: -moz-linear-gradient(
      to bottom,
      #ffcc00 0%,
      #ffcc00 27%,
      #ffd689 55%,
      #ffffff 100%
    );
    background: linear-gradient(
      to bottom,
      #ffcc00 0%,
      #ffcc00 27%,
      #ffd689 55%,
      #ffffff 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    .text {
      text-shadow: 0 0 1px #ffffff, 0 0 2px #ffffff;
    }

    svg image {
      filter: invert(0%) sepia(0%) saturate(0%) brightness(103%) contrast(100%);
    }
  }

  &.big,
  &.modalButton {
    width: 85%;
    height: 20%;
    position: absolute;
    bottom: 5%;
    text-transform: uppercase;
    overflow: visible;
    font-size: 250%;

    &:before {
      z-index: -1;
      right: -38%;
      margin-top: 3%;
      pointer-events: none;
      height: 225%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 175%;
      text-transform: uppercase;
      background-image: url("./assets/select.png");
    }
  }

  &.rectangleSmall {
    overflow: visible;
    padding: 0 3%;
    font-weight: 600;

    &:before {
      z-index: -1;
      right: -13%;
      margin-top: 2%;
      pointer-events: none;
      height: 180%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 125%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleSmall.png");
    }
  }
  &.rectangleLarge,
  &.big {
    overflow: visible;
    padding: 0 4%;

    &:before {
      z-index: -1;
      right: -12%;
      margin-top: 3%;
      pointer-events: none;
      height: 250%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 123%;
      text-transform: uppercase;
      background-image: url("./assets/rectangleLarge.png");
    }
  }
  &.square {
    aspect-ratio: 1;
    overflow: visible;

    &:before {
      z-index: -1;
      right: -131%;
      margin-top: 12%;
      pointer-events: none;
      height: 200%;
      width: 350%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      text-transform: uppercase;
      background-image: url("./assets/square.png");
    }

    svg {
      height: 100%;
      width: 100%;
      padding-top: 5px;
      padding-left: 3px;
    }

    svg image {
      transition: filter 0.18s ease-in;
    }

    &:last-child {
      svg {
        padding-top: 8px;
        padding-left: 4px;
      }
    }
  }
  &.claim {
    width: 185px;
    height: 69px;
    background-image: url("./assets/rectangleLarge.png");
    > span {
      margin-top: -4px;
    }
  }

  &.squareSmall {
    width: 45px;
    height: 45px;
  }
  &.buyMore {
    margin-bottom: 4px;
    font-size: 12px;
    width: 80px;
    height: 39px;
    background-image: url("./assets/buyMore.png");
  }
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.v-infoDropdown.cookingCrypto-infoDropdown {
  font-family: "Arial Rounded Bold", cursive;
  text-transform: uppercase;

  &:before {
    z-index: -1;
    right: -40%;
    top: -25%;
    pointer-events: none;
    height: 155%;
    width: 180%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    background-image: url("./assets/infoDropdown.png");
  }

  .cookingCrypto-infoDropdown-ul {
    .cookingCrypto-infoDropdown-li {
      font-size: 100%;
      background: -webkit-linear-gradient(#ffcc49, #fff06c);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }
}

.v-connect-dialog.cookingCrypto-connect-dialog {
  height: 320px;
  width: 344px;
  font-family: "Arial Rounded Bold";
  background-image: url("./assets/connectShrunk.png");
  button {
    margin-top: 28px;
  }
}

.v-actions.cookingCrypto-actions {
  font-family: "Arial Rounded Bold";

  &.cookingCrypto-actions-connect-wallet:before {
    background-image: url("./assets/connectedPizza.png");
    height: 138.5%;
    width: 138.5%;
    top: -42%;
    left: -18%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/connectExpanded.png");
    background-size: 100% 100%;
    height: 150%;
    width: 150%;
    content: "";
    position: absolute;
    top: -43%;
    left: -25%;
    pointer-events: none;
  }

  .connection-list-wrapper {
    padding: 10%;
    margin-top: -1%;
    height: 80%;
    transform: rotate(-1deg);

    .listElement span {
      font-size: 125%;
    }
  }

  .connection-wallet-logo {
    left: 15.5%;
    bottom: -6%;
    width: 70%;
  }

  > h2 {
    top: -25%;
    font-size: 5vmin;
    transform: rotate(-3deg);
    line-height: normal;

    > span {
      margin-left: 15px;
    }
    > .v-button {
      left: -5%;

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .v-claim-reward {
    margin: 12px;

    img {
      height: 133px;
      width: 133px;
      border-radius: 50%;
    }
  }
  .index-list-wrapper,
  .v-buyIndex {
    margin: 0;
    padding: 6% 8% 1% 4%;
  }
}

.v-selectListElement.cookingCrypto-selectListElement {
  margin-bottom: 3%;
  overflow: visible;
  padding-right: 20%;
  font-size: 110%;

  .v-selectListElement-name {
    font-size: 110%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/indexLi.png");
    background-size: 100% 100%;
    height: 240%;
    width: 120%;
    content: "";
    position: absolute;
    top: -62%;
    left: -8%;
    pointer-events: none;
  }

  &.selected:before {
    background-image: url("./assets/indexLiSelected.png");
  }
}

.v-stakeListElement.cookingCrypto-stakeListElement {
  overflow: visible;
  margin-bottom: 3%;
  font-size: 110%;

  &:before {
    z-index: -1;
    background-image: url("./assets/stakeLi.png");
    background-size: 100% 100%;
    height: 240%;
    width: 120%;
    content: "";
    position: absolute;
    top: -62%;
    left: -8%;
    pointer-events: none;
  }

  &:first-child {
    margin-top: 0;
  }

  .v-stakeListElement-icon {
    > svg {
      display: flex;
      justify-content: center;
    }
  }
  .v-stakeListElement-name {
    color: green;
    font-size: 110%;
  }

  .v-stakeListElement-amounts {
    margin-bottom: 1%;

    .v-stakeListElement-amounts-top-input {
      input {
        color: white;
        font-family: "Arial Rounded Bold";
      }
    }
    .v-stakeListElement-amounts-top-button {
      button {
        font-family: "Arial Rounded Bold";
        font-weight: bold;
        text-transform: uppercase;
        color: green;
      }
    }

    .v-stakeListElement-amounts-bottom {
      color: white;
    }
  }
}

.v-buyIndex.cookingCrypto-buyIndex {
  .v-buyIndex-top.cookingCrypto-buyIndex-top {
    position: relative;

    &:before {
      background-size: 100% 100%;
      background-image: url("assets/stakeLi.png");
      z-index: -1;
      height: 272%;
      width: 123%;
      content: "";
      position: absolute;
      top: -75%;
      left: -11%;
      pointer-events: none;
    }

    .v-buyIndex-top-dropdown.cookingCrypto-buyIndex-top-dropdown {
      .v-buyIndex-target.cookingCrypto-buyIndex-target {
        color: green;
        font-weight: bold;
      }
    }
  }
  .v-buyIndex-bottom.cookingCrypto-buyIndex-bottom {
    position: relative;
    margin-bottom: 5%;
    min-height: 28%;

    &:before {
      background-size: 100% 100%;
      background-image: url("assets/stakeLi.png");
      z-index: -1;
      height: 272%;
      width: 123%;
      content: "";
      position: absolute;
      top: -75%;
      left: -11%;
      pointer-events: none;
    }

    .v-buyIndex-bottom-asset.cookingCrypto-buyIndex-bottom-asset {
      font-weight: bold;
      color: green;
    }
    .v-buyIndex-bottom-values.cookingCrypto-buyIndex-bottom-values {
      color: white;
    }
  }
  .v-buyIndex-footer.cookingCrypto-buyIndex-footer {
    position: relative;
    margin-top: 10%;

    &:before {
      background-size: 100% 100%;
      background-image: url("assets/stakeLi.png");
      z-index: -1;
      height: 272%;
      width: 123%;
      content: "";
      position: absolute;
      top: -75%;
      left: -11%;
      pointer-events: none;
    }
  }
}

.v-buyIndex-popper.cookingCrypto-buyIndex-popper {
  font-family: "Arial Rounded Bold", sans-serif;
  font-weight: bold;
  color: green;
  border-radius: 4px;

  ul {
    height: 40px;
    line-height: 40px;
    width: 200px;

    li {
      background-image: url("assets/connectExpanded.png");
      background-repeat: no-repeat;
      background-position: center center;

      color: green;
      height: 40px;
      line-height: 40px;
      .token-icon {
        height: 3vmax;
        width: 3vmax;
      }

      > span {
        font-size: 20px;
      }
    }
  }
}

.v-claim.cookingCrypto-claim {
  height: 252px;
  .v-claim-reward {
    margin: auto;

    img {
      width: 100%;
      max-width: 150px;
      max-height: 150px;
      border-radius: 8px;
      margin: auto;
      background-color: #f0f1f3;
      box-shadow: 0px 0px 0px 3px #f0f1f3, 0px 0px 0px 6px #fbbda7,
        0px 0px 0px 9px #f0f1f3;
      object-fit: contain;
    }
  }
}
