.defiInvaders {
  font-family: "VCROSDMono";
  text-transform: uppercase;
  color: #77ddff;

  .v-input {
    //background: rgba(49, 100, 239, 0.2);
  }
  .splash {
    background-image: url("./assets/defiInvaders.png");
  }
}

.defiInvaders-select-indexes,
.defiInvaders-stake-indexes {
  > .index-list-scroll {
    height: 245px;
  }
}

.v-button.defiInvaders-button {
  font-family: "VCROSDMono";
  text-transform: uppercase;
  color: #77ddff;

  .text {
    transition: text-shadow 0.18s ease-in;
    filter: drop-shadow(0 0 1px white) drop-shadow(0 0 1px white);
  }

  &:hover {
    .text {
      color: #22e400;
      text-shadow: 0 0 1.5px #fff, 0 0 3px #fff;
    }

    svg image {
      filter: brightness(0) saturate(100%) invert(59%) sepia(83%)
        saturate(2732%) hue-rotate(72deg) brightness(110%) contrast(112%)
        drop-shadow(0 0 1px #ffffff) drop-shadow(0 0 3px #fff9f9)
        drop-shadow(0 0 4px #ffffff);
    }
  }

  &.big {
    background-image: url("./assets/big.png");
    text-transform: uppercase;
    width: 231px;
    height: 104px;
    font-size: 20px;
    text-align: center;
  }
  &.modalButton {
    margin-bottom: 10%;
    font-size: 175%;
    bottom: 0;
    width: 95%;
    height: 9%;
    position: absolute;
    text-transform: uppercase;
  }
  &.rectangleSmall {
    text-transform: uppercase;
    font-weight: bold;
    overflow: visible;
    padding: 0 3% 0 4%;

    &:before {
      z-index: -1;
      right: -12%;
      margin-top: 5%;
      pointer-events: none;
      height: 210%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 130%;
      padding-bottom: 5px;
      text-transform: uppercase;
      background-image: url("./assets/rectangleSmall.png");
      padding-left: 15px;
    }

    > svg {
      &:first-child {
        position: absolute;
        right: initial;
        left: 12px;
        top: -4px;
      }
    }
  }
  &.rectangleLarge {
    overflow: visible;
    padding-left: 15px;
    padding: 0 4% 0 6%;
    margin: 0 1%;

    &:before {
      z-index: -1;
      right: -12%;
      margin-top: 5%;
      pointer-events: none;
      height: 210%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      width: 130%;
      padding-bottom: 5px;
      text-transform: uppercase;
      background-image: url("./assets/rectangleLarge.png");
      padding-left: 15px;
    }
  }
  &.rectangleLargeWarning {
    width: 137px;
    height: 40px;
    font-size: 12px;
    background-image: url("./assets/rectangleLargeWarning.png");
  }
  &.square {
    width: 52px;
    height: 52px;
    font-weight: bold;
    font-size: 22px;
    background-image: url("./assets/square.png");
    > span {
      margin-top: 6px;
    }
  }
  &.claim {
    color: #77ddff;

    width: 136px;
    height: 39px;
    font-size: 12px;
    text-transform: uppercase;
  }
  &.squareSmall {
    aspect-ratio: 1;
    overflow: visible;

    &:before {
      z-index: -1;
      left: -50%;
      margin-top: 15%;
      pointer-events: none;
      height: 200%;
      width: 200%;
      background-size: 100% 100%;
      content: "";
      position: absolute;
      text-transform: uppercase;
      background-image: url("./assets/squareSmall.png");
    }

    svg {
      height: 65%;
      width: 65%;
    }

    svg image {
      transition: filter 0.18s ease-in;
    }
  }
  &.buyMore {
    width: 89px;
    height: 40px;
    font-size: 10px;
    margin: 16px 0 22px 0;
  }
  &.back {
    width: 63px;
    height: 44px;
    left: 0;
    background: transparent;
    border: none;
    margin: 0;
    margin-bottom: 8px;
    padding: 0;
  }
}

.v-infoDropdown.defiInvaders-infoDropdown {
  text-transform: uppercase;
  font-family: "VCROSDMono";
  text-transform: uppercase;
  color: #77ddff;

  &:before {
    z-index: -1;
    right: -27%;
    top: -18%;
    pointer-events: none;
    height: 145%;
    width: 160%;
    background-size: 100% 100%;
    content: "";
    position: absolute;
    text-transform: uppercase;
    background-image: url("./assets/infoDropdown.png");
  }
}

.v-connect-dialog.defiInvaders-connect-dialog {
  font-family: "VCROSDMono";
  text-transform: uppercase;
  color: #77ddff;
  background-image: url("./assets/connectShrunk.png");
  height: 299px;
  width: 518px;

  h2 {
    margin-top: 4px;
    font-size: 20px;
  }
  p {
    display: flex;
    justify-content: center;
    button {
      margin-top: 48px;
    }
  }
}

.mobile .v-actions.defiInvaders-actions {
  max-height: 80%;
  top: 18%;
  left: 28%;
  right: 29%;
}

.v-actions.defiInvaders-actions {
  font-family: "VCROSDMono";
  text-transform: uppercase;
  color: #77ddff;

  h2 {
    font-size: 3.5vmin;
  }

  @media screen and (max-height: 450px), screen and (max-width: 450px) {
    max-height: 80%;
    top: 18%;
    left: 28%;
    right: 29%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/connectExpanded.png");
    background-size: 100% 100%;
    height: 115%;
    width: 111%;
    content: "";
    position: absolute;
    top: -18%;
    left: -6%;
    pointer-events: none;
  }

  .v-buy-indexes,
  .v-select-indexes,
  .v-stake-indexes,
  .connection-list-wrapper {
    height: 100%;
    justify-content: start !important;
    padding: 0 12% 0 10%;
    width: 100%;
  }

  .connection-list-wrapper {
    height: 77%;

    &:before {
      z-index: -1;
      background-image: url("./assets/cross.png");
      background-size: 100% 100%;
      height: 103%;
      width: 80%;
      content: "";
      position: absolute;
      top: -1.5%;
      left: 9%;
      pointer-events: none;
    }
  }

  .connection-wallet-logo {
    left: 9.5%;
    bottom: -16%;
    width: 80%;
  }

  h2 {
    text-transform: uppercase;
    margin-bottom: 0;
    padding-bottom: 0;
    top: -16%;
  }
}

.v-selectListElement.defiInvaders-selectListElement {
  margin: 7% 0 3% 0;
  overflow: visible;
  padding: 1% 17.5% 1% 5%;
  font-size: 110%;

  .v-selectListElement-name {
    font-size: 110%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/indexLi.png");
    background-size: 100% 100%;
    height: 150%;
    width: 100%;
    content: "";
    position: absolute;
    top: 17.5%;
    left: 0%;
    pointer-events: none;
  }

  &.selected:before {
    background-image: url("./assets/indexLiSelected.png");
  }
}

.v-stakeListElement.defiInvaders-stakeListElement {
  margin: 7% 0 3% 0;
  padding: 1% 5% 1% 5%;
  overflow: visible;
  font-size: 110%;

  .v-stakeListElement-name {
    font-size: 110%;
  }

  &:before {
    z-index: -1;
    background-image: url("./assets/stakeLi.png");
    background-size: 100% 100%;
    height: 150%;
    width: 100%;
    content: "";
    position: absolute;
    top: 20%;
    left: 0%;
    pointer-events: none;
  }

  .v-stakeListElement-icon {
    > svg {
      display: flex;
      justify-content: center;
    }
  }
  .v-stakeListElement-name {
    color: #77ddff;
  }

  .v-stakeListElement-amounts {
    .v-stakeListElement-amounts-top-input {
      input {
        color: #77ddff;
        font-family: "VCROSDMono", serif;
        text-transform: uppercase;

        &::placeholder {
          color: #77ddff;
        }
      }
    }
    .v-stakeListElement-amounts-top-button {
      button {
        font-family: "VCROSDMono";
        text-transform: uppercase;
        font-weight: bold;
        text-transform: uppercase;
        color: #98de01;
      }
    }
  }
}

.v-buyIndex.defiInvaders-buyIndex {
  .v-buyIndex-top.defiInvaders-buyIndex-top {
    margin-top: 2vmax;

    &:before {
      z-index: -1;
      background-image: url("./assets/stakeLi.png");
      background-size: 100% 100%;
      height: 150%;
      width: 100%;
      content: "";
      position: absolute;
      left: 0%;
      top: 0;
      pointer-events: none;
    }

    .v-buyIndex-top-dropdown.defiInvaders-buyIndex-top-dropdown {
      .v-buyIndex-target.defiInvaders-buyIndex-target {
        color: #77ddff;
        font-weight: bold;
      }
    }
  }

  .v-buyIndex-bottom.defiInvaders-buyIndex-bottom {
    &:before {
      z-index: -1;
      background-image: url("./assets/stakeLi.png");
      background-size: 100% 100%;
      height: 150%;
      width: 100%;
      content: "";
      position: absolute;
      left: 0%;
      top: 0;
      pointer-events: none;
    }
  }
  .v-buyIndex-footer.defiInvaders-buyIndex-footer {
    text-transform: uppercase;
    margin-top: 3vmax;
  }
}

.v-buyIndex-popper.defiInvaders-buyIndex-popper {
  font-family: "VCROSDMono";
  text-transform: uppercase;
  border-radius: 0px 0px 12px 12px;

  ul {
    background: linear-gradient(#08082d, #353772, #08082d);
    li {
      color: #77ddff;
    }
  }
}

.v-claim.defiInvaders-claim {
  height: 288px;

  img {
    width: 100%;
    max-width: 204px;
    max-height: 204px;
    border-radius: 0;
    margin: auto;
    background-color: #404d74;
    object-fit: contain;
    clip-path: polygon(
      10% 0%,
      90% 0%,
      100% 10%,
      100% 90%,
      90% 100%,
      10% 100%,
      0% 90%,
      0% 10%
    );
  }
}
